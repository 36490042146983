// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBS7p9rBUyHKJseYoy8VPDjn5-LsTWVQnc",
  authDomain: "dj-website-djsedrik.firebaseapp.com",
  projectId: "dj-website-djsedrik",
  storageBucket: "dj-website-djsedrik.appspot.com",
  messagingSenderId: "438693142326",
  appId: "1:438693142326:web:50c079693bcd06e96cdd97",
  measurementId: "G-28FSJ9QVPX"
};
export const siteKey = "6LeLFxMkAAAAAOnUowE3addAKja2GKsoOMuc313s"
