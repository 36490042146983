import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: () => import("../views/PrivacyPolicyView.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () => import("../views/ImpressumView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo: any = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  routes,
});

export default router;
