<template>
  <div class="text-center justify-center" style="height: 300px">
    <h3>
      Follow me on <br />
      Instagram
    </h3>
    <div class="d-flex flex-wrap justify-center">
      <v-btn
        v-for="(icon, i) of socialMediaIcons"
        :key="i"
        :href="icon.link"
        color="primary"
        fab
        class="ma-3"
        x-large
      ><v-icon v-text="icon.icon"
      /></v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FollowComponent",
  data: () => ({
    socialMediaIcons: [
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/djsedrik/",
      },
    ],
  }),
};
</script>