<template>
  <v-alert
    border="left"
    class="privacy_policy_alert"
    color="secondary"
    colored-border
    dismissible
    elevation="2"
  >
    Bei benutzen dieser Webseite stimmen Sie unserer
    <v-btn class="pa-0" link plain to="/privacy_policy">Datenschutzbestimmung</v-btn>
    zu.
  </v-alert>
</template>

<script>
export default {
  name: "PrivacyPolicyAlert",
};
</script>

<style scoped>
.privacy_policy_alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
</style>
