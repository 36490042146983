
import Vue from "vue";

export default Vue.extend({
  name: "AboutComponent",
  computed: {
    calculate_age() {
      const today_date: Date = new Date();
      const today_year: number = today_date.getFullYear();
      const today_month: number = today_date.getMonth();
      const today_day: number = today_date.getDate();
      let age = today_year - 2000;

      if (today_month < 9 - 1) {
        age--;
      }
      if (9 - 1 == today_month && today_day < 24) {
        age--;
      }
      return age;
    },
  },
});
