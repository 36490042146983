<template>
  <v-parallax
    src="@/assets/webp/photoshoot_02.webp"
    height="300"
    class="component-margin"
  >
    <v-overlay
      color="black"
      opacity="0.8"
      value="True"
      absolute
      class="text-center"
    >
      <h3>DJ für</h3>
      <h2 v-if="advertisementParallax === 0">den Geburtstag</h2>
      <h2 v-if="advertisementParallax === 1">die Firmenfeier</h2>
      <h2 v-if="advertisementParallax === 2">die Dorfdisco</h2>
      <h2 v-if="advertisementParallax === 3">den Club</h2>
      <h2 v-if="advertisementParallax === 4">die Bar</h2>
    </v-overlay>
  </v-parallax>
</template>

<script>
export default {
  name: "ParallaxRotationComponent",
  mounted() {
    setInterval(() => {
      if (this.advertisementParallax < 4) {
        this.advertisementParallax++;
      } else {
        this.advertisementParallax = 0;
      }
    }, 3000);
  },
  data: () => ({
    advertisementParallax: 0,
    timer: undefined,
  }),
};
</script>