<template>
  <div class="text-left">
    <h2>
      <v-icon color="secondary" style="top: -5px" x-large>
        mdi-book-open-blank-variant
      </v-icon>
      Kontakt&shy;formular
    </h2>
    <p>
      Du möchtest mich buchen oder hast eine andere Frage. <br />
      Schreibe mir doch über dieses Formular. <br />
      Ich werde deine Anfrage umgehend beantworten.
    </p>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        filled
        v-model="bookingForm.name"
        :counter="30"
        :rules="rules.name"
        label="Firma / Name*"
        required
      />

      <v-text-field
        filled
        v-model="bookingForm.email"
        :rules="rules.email"
        label="E-mail*"
        required
      />

      <v-text-field
        filled
        v-model="bookingForm.subject"
        :rules="rules.subject"
        label="Anliegen*"
        required
      />

      <v-textarea
        name="input-7-1"
        filled
        label="Details"
        :rules="rules.body"
        auto-grow
        v-model="bookingForm.body"
      />

      <v-text-field
        style="display: none"
        v-model="bookingForm.fakeField"
      ></v-text-field>

      <v-row>
        <v-col cols="12" md="3">
          <v-btn @click="onSubmitClick()" block color="secondary">
            Abschicken
          </v-btn>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="bookingForm.agreed"
            class="my-0"
            :rules="[
              (v) =>
                !!v ||
                'Um das Formular abzuschicken musst du der Datenschutzerklärung zustimmen',
            ]"
            label="Hiermit bestätigen ich, dass ich die Datenschutzbestimmung gelesen habe und dieser zustimme!"
            required
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>

import { writeFormToDb } from "../../../firestore";

export default {
  name: "BookingView",
  data: () => ({
    bookingForm: {
      name: "",
      email: "",
      subject: "",
      body: "",
      fakeField: "",
      agreed: false,
    },
    valid: false,
    rules: {
      name: [
        (v) => !!v || "Dieses Feld muss ausgefüllt sein!",
        (v) =>
          (v && v.length <= 40 && v.length >= 2) || "Zwischen 2 - 40 Zeichen",
      ],
      email: [
        (v) => !!v || "Dieses Feld muss ausgefüllt sein!",
        (v) => !v || /.+@.+\..+/.test(v) || "Keine Valide Email!",
      ],
      subject: [
        (v) => !!v || "Dieses Feld muss ausgefüllt sein!",
        (v) =>
          (v && v.length <= 100 && v.length >= 5) || "Zwischen 5 - 100 Zeichen",
      ],
      body: [
        (v) => !!v || "Dieses Feld muss ausgefüllt sein!",
        (v) =>
          (v && v.length <= 500 && v.length >= 10) ||
          "Zwischen 10 - 500 Zeichen",
      ],
    },
  }),
  methods: {
    async onSubmitClick() {
      let areAllFieldsWritten =
        this.bookingForm.subject.length > 0 &&
        this.bookingForm.name.length > 0 &&
        this.bookingForm.email.length > 0 &&
        this.bookingForm.body.length > 0;

      let isFakeFieldWritten = this.bookingForm.fakeField.length > 0;

      if (
        !this.$refs.form.isEmpty &&
        this.$refs.form.validate() &&
        !isFakeFieldWritten
      ) {
        await writeFormToDb(
          this.bookingForm.subject,
          this.bookingForm.name,
          this.bookingForm.email,
          this.bookingForm.body
        ).then(
          () => {
            this.showSuccessToast();
            this.$refs.form.reset();
          },
          (reason) => this.showErrorToast(reason.message)
        );
      } else if (!this.bookingForm.agreed && areAllFieldsWritten) {
        this.showPrivacyErrorToast();
      } else {
        this.showErrorToast("Formular nicht gültig");
      }
    },
    showSuccessToast() {
      this.$toast.success("Formular wurde gesendet", {
        position: "bottom-center",
        timeout: 1968,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    showErrorToast(reason) {
      this.$toast.error(reason, {
        position: "bottom-center",
        timeout: 1968,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    showPrivacyErrorToast() {
      this.$toast.error("Sie müssen den Datenschutzbestimmungen zustimmen", {
        position: "bottom-center",
        timeout: 1968,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style scoped>
.error-alert {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
