import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/src/locale/de";
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { de },
    current: "de",
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#e88300",
        secondary: "#c50606",
        accent: "#F9B316",
        error: "#991111",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#b96901",
      },
    },
  },
});
