
import Vue from "vue";
import GradientParallaxComponent from "@/components/app/GradientParallaxComponent.vue";
import ContactComponent from "@/components/home/ContactComponent.vue";
import AboutComponent from "@/components/home/AboutComponent.vue";
import EquipmentComponent from "@/components/home/EquipmentComponent.vue";
import KnowledgeComponent from "@/components/home/KnowledgeComponent.vue";
import WhyMeComponent from "@/components/home/WhyMeComponent.vue";
import FollowComponent from "@/components/home/FollowComponent.vue";
import ParallaxRotationComponent from "@/components/home/ParallaxRotationComponent.vue";

export default Vue.extend({
  name: "HomeView",
  components: {
    ParallaxRotationComponent,
    FollowComponent,
    WhyMeComponent,
    KnowledgeComponent,
    EquipmentComponent,
    AboutComponent,
    ContactComponent,
    GradientParallaxComponent,
  },
});
