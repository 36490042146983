<template>
  <div>
    <v-app-bar app color="transparent" elevation="0">
      <v-spacer />

      <v-btn
        class="nav-button"
        large
        plain
        link
        to="/#about"
      >
        Über mich
      </v-btn>

      <v-btn
        v-if="!showMenuIcon"
        class="nav-button"
        large
        link
        to="/#knowledge"
        plain
      >
        Erfahrung
      </v-btn>

      <router-link to="/">
        <v-img
          max-width="80px"
          class="mt-5"
          src="@/assets/webp/logo-white.webp"
        ></v-img>
      </router-link>

      <v-btn
        v-if="!showMenuIcon"
        class="nav-button"
        large
        plain
        link
        to="/#equipment"
      >
        Equipment
      </v-btn>

      <v-btn
        class="nav-button"
        color="primary"
        large
        plain
        link
        to="/#contact"
      >
        Kontakt
      </v-btn>

      <v-spacer />
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",

  computed: {
    showMenuIcon() {
      let br = this.$vuetify.breakpoint.name;
      let showMenuIcon = true;

      if (br === "lg" || br === "xl" || br === "md") {
        showMenuIcon = false;
      }
      return showMenuIcon;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-button {
  font-size: 1.1em !important;
  font-weight: 700;
}
</style>
