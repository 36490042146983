import firebase from "./firebase";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const db = getFirestore(firebase);
const bookingCollection = collection(db, "booking");

export function writeFormToDb(
  subject: string,
  name: string,
  email: string,
  body: string,
) {
  const data = {
    subject: subject,
    name: name,
    email: email,
    body: body,
    timestamp: Date.now()
  };
  return addDoc(bookingCollection, data);
}
