
import Vue from "vue";
import NavbarComponent from "@/components/app/NavbarComponent.vue";
import FooterComponent from "@/components/app/FooterComponent.vue";
import PrivacyPolicyAlert from "@/components/app/PrivacyPolicyAlert.vue";
import ScrollToTopComponent from "@/components/app/ScrollToTopComponent.vue";

export default Vue.extend({
  name: "App",
  components: {
    ScrollToTopComponent,
    PrivacyPolicyAlert,
    NavbarComponent,
    FooterComponent,
  },
});
