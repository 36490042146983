import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig, siteKey }  from "./environments/env";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);

//const analytics = getAnalytics(firebase);

// const appCheck = initializeAppCheck(firebase, {
//   provider: new ReCaptchaV3Provider(siteKey),
//   isTokenAutoRefreshEnabled: true,
// });

export default firebase;
