<template>
  <v-footer class="text-center" dark padless>
    <v-card flat tile width="100%">
      <v-card-text>
        <v-btn
          v-for="iconObj in socialMediaIcons"
          :key="iconObj.icon"
          :href="iconObj.link"
          class="mx-5"
          icon
        >
          <v-icon color="secondary" size="24px">
            {{ iconObj.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="pt-0 row mx-auto" style="background-color: #1a1a1a">
        <v-row class="w-100" justify="center">
          <v-col cols="12" lg="3" md="4">
            <div class="w-80 my-5 mx-auto">
              <h4 class="mb-5">Links</h4>
              <v-btn block link text to="/privacy_policy"> Datenschutz </v-btn>
              <v-btn block text link to="/impressum"> Impressum </v-btn>
            </div>
          </v-col>
          <v-col cols="12" lg="3" md="4">
            <div class="w-80 my-5 mx-auto">
              <h4 class="mb-5">Kontakt</h4>
              <v-btn class="ma-2" :href="'mailto:' + email" text small>
                <v-icon color="orange" class="mr-3">mdi-email</v-icon>
                {{ email }}
              </v-btn>
              <v-btn class="ma-2" text small>
                <v-icon color="orange" class="mr-3"> mdi-whatsapp </v-icon>
                {{ whatsapp }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" lg="3" md="4">
            <div class="w-80 my-5 mx-auto">
              <h4 class="mb-5">Standort</h4>
              <v-img
                alt="Standort"
                class="mx-auto"
                max-height="150"
                @click="overlay = true"
                contain
                src="@/assets/webp/map_bw_orange.webp"
                width="250px"
              />

              <v-overlay z-index="0" :value="overlay">
                <v-btn fab color="primary" @click="overlay = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-img
                  alt="Standort"
                  class="mx-auto"
                  @click="overlay = false"
                  width="50%"
                  contain
                  src="@/assets/webp/map_bw_orange.webp"
                />
              </v-overlay>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text style="background-color: #121212">
        Copyright {{ new Date().getFullYear() }} — <strong>Dj Sedrik</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    email: "sedrik.staack@gmx.de",
    phone: "0000-0000",
    whatsapp: "+49 15231382360",
    socialMediaIcons: [
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/djsedrik/",
      },
      {
        icon: "mdi-email",
        link: "mailto:sedrik.staack@gmx.de",
      },
    ],
    overlay: false,
  }),
};
</script>
<style scoped>
.w-80 {
  width: 80%;
}
</style>
