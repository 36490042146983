<template>
  <div>
    <v-row>
      <v-carousel
        v-model="carousel_index"
        cycle
        show-arrows-on-hover
        continuous
        hide-delimiters
        :height="getGalleryImageHeight"
        class="rounded"
      >
        <v-carousel-item
          v-for="(image, i) in images"
          :key="i"
          :src="image"
        ></v-carousel-item>
      </v-carousel>
    </v-row>

    <v-row>
      <v-slide-group v-model="carousel_index" show-arrows center-active>
        <v-slide-item v-for="(image, i) in images" :key="i" v-slot="{ toggle }">
          <v-card
            elevation="0"
            class="ma-1"
            color="transparent"
            @click="toggle"
          >
            <v-img
              :src="image"
              :style="getGallerySmallImageSize"
              class="ma-1"
            />
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    carousel_index: 0,
    images: [
      require("@/assets/webp/eisdisco_08.webp"),
      require("@/assets/webp/sedrik_hochzeit.webp"),
      require("@/assets/webp/eisdisco_11.webp"),
      require("@/assets/webp/eisdisco_13.webp"),
      require("@/assets/webp/eisdisco_15.webp"),
      require("@/assets/webp/club_02.webp"),
      require("@/assets/webp/hochzeit_01.webp"),
      require("@/assets/webp/hochzeit_02.webp"),
      require("@/assets/webp/photoshoot_01.webp"),
      require("@/assets/webp/photoshoot_02.webp"),
      require("@/assets/webp/photoshoot_03.webp"),
    ],
  }),
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
    getGalleryImageHeight() {
      let br = this.$vuetify.breakpoint.name;
      if (br === "xs" || br === "sm") {
        return "300";
      } else {
        return "600";
      }
    },
    getGallerySmallImageSize() {
      let br = this.$vuetify.breakpoint.name;
      if (br === "xs" || br === "sm") {
        return {
          height: "50px",
          width: "70px",
        };
      } else {
        return {
          height: "75px",
          width: "100px",
        };
      }
    },
  },
};
</script>
<style scoped>
.small-pic-outline-transparent {
  background-color: transparent;
}
</style>
