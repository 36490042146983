<template>
  <v-btn
    elevation="10"
    color="primary"
    fab
    class="scroll-top-btn"
    @click="onClick()"
  >
    <v-icon large>mdi-chevron-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ScrollToTopComponent",
  methods: {
    onClick() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.scroll-top-btn {
  position: fixed;
  right: 2%;
  bottom: 5%;
  z-index: 1;
}
</style>
