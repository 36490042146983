<template>
  <v-parallax :height="getParallaxHeight" :src="picture"  dark>
    <v-container>
      <h1 class="text-wrap mb-3" style="line-height: 0.8">DJ Sedrik</h1>
      <p>
        Du bist auf der Suche nach einem DJ? <br />
        Dann melde dich jetzt bei mir.
      </p>
      <v-btn color="primary" @click="$vuetify.goTo('#contact')" large>
        Kontakt
      </v-btn>
    </v-container>
    <div class="parallax-gradient" />
  </v-parallax>
</template>

<script>
export default {
  name: "GradientParallax",
  props: {
    picture: String,
  },
  computed: {
    getParallaxHeight() {
      let height = 500;
      if (window.innerHeight > height) {
        height = window.innerHeight
      }
      return height;
    },
  },
};
</script>

<style lang="scss" scoped>
.parallax-gradient {
  background: linear-gradient(to top, #121212 0, rgba(0, 0, 0, 0.4) 50%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}
</style>
